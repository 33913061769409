<template>
  <div>
    <b-tabs content-class="mt-2" justified>
            <b-tab v-if="ranking_valor.length > 0" title="Valor">
              <b-row>
                <b-col>
                  <b-card>
                    <div class="text-center mt-2">
                      <b-row>
                        <b-col>
                          <div>
                            <p class="mt-5">
                              <b-img
                                fluid
                                width="80"
                                :src="require('@/assets/images/undraw/medal_bronze.svg')"
                              />
                            </p>
                            <h2>3º {{ ranking_valor[2].colaborador }}</h2>
                            <p>
                              <b>Valor Vendido:</b>
                              {{ ranking_valor[2].valor | dinheiro }}
                            </p>
                          </div>
                        </b-col>
                        <b-col>
                          <div>
                            <p>
                              <b-img
                                fluid
                                width="120"
                                :src="require('@/assets/images/undraw/medal.svg')"
                              />
                            </p>
                            <h2>1º {{ ranking_valor[0].colaborador }}</h2>
                            <p>
                              <b>Valor Vendido:</b>
                              {{ ranking_valor[0].valor | dinheiro }}
                            </p>
                          </div>
                        </b-col>
                        <b-col>
                          <div>
                            <p class="mt-5">
                              <b-img
                                fluid
                                width="80"
                                :src="require('@/assets/images/undraw/medal_prata.svg')"
                              />
                            </p>
                            <h2>2º {{ ranking_valor[1].colaborador }}</h2>
                            <p>
                              <b>Valor Vendido:</b>
                              {{ ranking_valor[1].valor | dinheiro }}
                            </p>
                          </div>
                        </b-col>
                      </b-row>
                      <hr>
                      <b-row>
                        <b-col lg="2" class="text-right">
                          <b-img
                            fluid
                            width="50"
                            :src="require('@/assets/images/undraw/medal02.svg')"
                          />
                        </b-col>
                        <b-col lg="6" class="text-left mt-1">
                          <h4>4º {{ ranking_valor[3].colaborador }}</h4>
                        </b-col>
                        <b-col lg="4" class="text-left mt-1">
                          <b>Valor Vendido:</b>
                              {{ ranking_valor[3].valor | dinheiro }}
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col lg="2" class="text-right">
                          <b-img
                            fluid
                            width="50"
                            :src="require('@/assets/images/undraw/medal02.svg')"
                          />
                        </b-col>
                        <b-col lg="6" class="text-left mt-1">
                          <h4>5º {{ ranking_valor[4].colaborador }}</h4>
                        </b-col>
                        <b-col lg="4" class="text-left mt-1">
                          <b>Valor Vendido:</b>
                              {{ ranking_valor[4].valor | dinheiro }}
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card no-body>
                    <div class="ranking-style overflow-auto">
                      <b-table-simple striped responsive>
                        <b-thead>
                          <b-tr class="text-center">
                            <b-th>#</b-th>
                            <b-th>Nome</b-th>
                            <b-th>Valor</b-th>
                            <b-th>Ultima Atualização</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr
                            :key="indextr"
                            v-for="(tr, indextr) in ranking_valor"
                          >
                            <b-td class="text-center">
                              <feather-icon
                                icon="AwardIcon"
                                class="ranking-gold"
                                v-if="indextr == 0"
                              />
                              <feather-icon
                                icon="AwardIcon"
                                class="ranking-silver"
                                v-if="indextr == 1"
                              />
                              <feather-icon
                                icon="AwardIcon"
                                class="ranking-bronze"
                                v-if="indextr == 2"
                              />
                              <b v-if="indextr > 2">{{ indextr }}</b>
                            </b-td>
                            <b-td class="text-center">
                            PA {{tr.PA}} - {{ tr.colaborador }}
                            </b-td>
                            <b-td class="text-center">
                              {{ tr.valor | dinheiro }}
                            </b-td>
                            <b-td class="text-center">
                              {{ tr.data | dateTimeFormat }}
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab v-if="ranking_media.length > 0" title="Média">
              <b-row>
                <b-col>
                  <b-card>
                    <div class="text-center mt-2">
                      <b-row>
                        <b-col>
                          <div>
                            <p class="mt-5">
                              <b-img
                                fluid
                                width="80"
                                :src="require('@/assets/images/undraw/medal_bronze.svg')"
                              />
                            </p>
                            <h3>3º {{ ranking_media[2].colaborador }}</h3>
                            <p>
                              <b>Média:</b>
                              {{ ranking_media[2].media | media }}
                            </p>
                          </div>
                        </b-col>
                        <b-col>
                          <div>
                            <p>
                              <b-img
                                fluid
                                width="120"
                                :src="require('@/assets/images/undraw/medal.svg')"
                              />
                            </p>
                            <h2>1º {{ ranking_media[0].colaborador }}</h2>
                            <p>
                              <b>Média:</b>
                              {{ ranking_media[0].media | media}}
                            </p>
                          </div>
                        </b-col>
                        <b-col>
                          <div>
                            <p class="mt-5">
                              <b-img
                                fluid
                                width="80"
                                :src="require('@/assets/images/undraw/medal_prata.svg')"
                              />
                            </p>
                            <h3>2º {{ ranking_media[1].colaborador }}</h3>
                            <p>
                              <b>Média:</b>
                              {{ ranking_media[1].media | media}}
                            </p>
                          </div>
                        </b-col>
                      </b-row>
                      <hr>
                      <b-row>
                        <b-col lg="2" class="text-right">
                          <b-img
                            fluid
                            width="50"
                            :src="require('@/assets/images/undraw/medal02.svg')"
                          />
                        </b-col>
                        <b-col lg="6" class="text-left mt-1">
                          <h4>4º {{ ranking_media[3].colaborador }}</h4>
                        </b-col>
                        <b-col lg="4" class="text-left mt-1">
                          <b>Média:</b>
                              {{ ranking_media[3].media | media }}
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col lg="2" class="text-right">
                          <b-img
                            fluid
                            width="50"
                            :src="require('@/assets/images/undraw/medal02.svg')"
                          />
                        </b-col>
                        <b-col lg="6" class="text-left mt-1">
                          <h4>5º {{ ranking_media[4].colaborador }}</h4>
                        </b-col>
                        <b-col lg="4" class="text-left mt-1">
                          <b>Média:</b>
                              {{ ranking_media[4].media | media }}
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card no-body>
                    <div class="ranking-style overflow-auto">
                      <b-table-simple striped responsive>
                        <b-thead>
                          <b-tr class="text-center">
                            <b-th>#</b-th>
                            <b-th>Nome</b-th>
                            <b-th>Média</b-th>
                            <b-th>Ultima Atualização</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr
                            :key="indextr"
                            v-for="(tr, indextr) in ranking_media"
                          >
                            <b-td class="text-center">
                              <feather-icon
                                icon="AwardIcon"
                                class="ranking-gold"
                                v-if="indextr == 0"
                              />
                              <feather-icon
                                icon="AwardIcon"
                                class="ranking-silver"
                                v-if="indextr == 1"
                              />
                              <feather-icon
                                icon="AwardIcon"
                                class="ranking-bronze"
                                v-if="indextr == 2"
                              />
                              <b v-if="indextr > 2"></b>
                            </b-td>
                            <b-td class="text-center">
                             PA {{tr.PA}} - {{ tr.colaborador }}
                            </b-td>
                            <b-td class="text-center">
                              {{ tr.media | media}}
                            </b-td>
                            <b-td class="text-center">
                              {{ tr.data | dateTimeFormat }}
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab v-if="ranking_quantidade.length > 0" title="Quantidade">
              <b-row>
                <b-col>
                  <b-card>
                    <div class="text-center mt-2">
                      <b-row>
                        <b-col>
                          <div>
                            <p class="mt-5">
                              <b-img
                                fluid
                                width="80"
                                :src="require('@/assets/images/undraw/medal_bronze.svg')"
                              />
                            </p>
                            <h3>3º {{ ranking_quantidade[2].colaborador }}</h3>
                            <p>
                              <b>Quantidade Vendida:</b>
                              {{ ranking_quantidade[2].quantidade }}
                            </p>
                          </div>
                        </b-col>
                        <b-col>
                          <div>
                            <p>
                              <b-img
                                fluid
                                width="120"
                                :src="require('@/assets/images/undraw/medal.svg')"
                              />
                            </p>
                            <h2>1º {{ ranking_quantidade[0].colaborador }}</h2>
                            <p>
                              <b>Quantidade Vendida:</b>
                              {{ ranking_quantidade[0].quantidade }}
                            </p>
                          </div>
                        </b-col>
                        <b-col>
                          <div>
                            <p class="mt-5">
                              <b-img
                                fluid
                                width="80"
                                :src="require('@/assets/images/undraw/medal_prata.svg')"
                              />
                            </p>
                            <h3>2º {{ ranking_quantidade[1].colaborador }}</h3>
                            <p>
                              <b>Quantidade Vendida:</b>
                              {{ ranking_quantidade[1].quantidade  }}
                            </p>
                          </div>
                        </b-col>
                      </b-row>
                      <hr>
                      <b-row>
                        <b-col lg="2" class="text-right">
                          <b-img
                            fluid
                            width="50"
                            :src="require('@/assets/images/undraw/medal02.svg')"
                          />
                        </b-col>
                        <b-col lg="6" class="text-left mt-1">
                          <h4>4º {{ ranking_quantidade[3].colaborador }}</h4>
                        </b-col>
                        <b-col lg="4" class="text-left mt-1">
                          <b>Quantidade Vendida:</b>
                              {{ ranking_quantidade[3].quantidade }}
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col lg="2" class="text-right">
                          <b-img
                            fluid
                            width="50"
                            :src="require('@/assets/images/undraw/medal02.svg')"
                          />
                        </b-col>
                        <b-col lg="6" class="text-left mt-1">
                          <h4>5º {{ ranking_quantidade[4].colaborador }}</h4>
                        </b-col>
                        <b-col lg="4" class="text-left mt-1">
                          <b>Quantidade Vendida:</b>
                              {{ ranking_quantidade[4].quantidade }}
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card no-body>
                    <div class="ranking-style overflow-auto">
                      <b-table-simple striped responsive>
                        <b-thead>
                          <b-tr class="text-center">
                            <b-th>#</b-th>
                            <b-th>Nome</b-th>
                            <b-th>Quantidade</b-th>
                            <b-th>Ultima Atualização</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr
                            :key="indextr"
                            v-for="(tr, indextr) in ranking_quantidade"
                          >
                            <b-td class="text-center">
                              <feather-icon
                                icon="AwardIcon"
                                class="ranking-gold"
                                v-if="indextr == 0"
                              />
                              <feather-icon
                                icon="AwardIcon"
                                class="ranking-silver"
                                v-if="indextr == 1"
                              />
                              <feather-icon
                                icon="AwardIcon"
                                class="ranking-bronze"
                                v-if="indextr == 2"
                              />
                              <b v-if="indextr > 2"></b>
                            </b-td>
                            <b-td class="text-center">
                              PA {{tr.PA}} - {{ tr.colaborador }}
                            </b-td>
                            <b-td class="text-center">
                              {{ tr.quantidade }}
                            </b-td>
                            <b-td class="text-center">
                              {{ tr.data | dateTimeFormat }}
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
    <div v-show="ranking_valor.length == 0" class="text-center">
      <b-card>
        <h2>Encontramos nenhuma informaçao sobre o ranking atual!</h2>
        <p>
          <small class="text-muted"
            >Caso isso seja um equívoco entre em contato com o responsável do
            sistema!</small
          >
        </p>
        <p class="mt-2">
          <b-img
            fluid
            width="300"
            :src="require('@/assets/images/pages/investimentos.svg')"
          />
        </p>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BTabs, BTab, BCardText } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardText,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Variáveis Global
      isLoading: true,
      ranking_valor: {},
      ranking_quantidade: {},
      ranking_media: {},

      //Paginação
      perPage: 9,
      currentPage: 1,
      totalRows: null,
    };
  },
  computed: {
    ...mapState("connectAPI", ["modules"]),
  },
  methods: {
    /*
    / Listar e paginar
    */
    fetchModules(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("connectAPI/fetchRanking", {
          pathAPI: "index",
          paginated: paginated,
          page: page,
          per_page: this.perPage,
          produto_id: localStorage.getItem("produto_id"),
          agencia_id: localStorage.getItem("agencia_id"),
          regional_id: localStorage.getItem("regional_id"),
        })
        .then((response) => {
          let valor = [];
          let quantidade = [];
          let media = [];
          this.ranking_valor = {};
          this.ranking_quantidade = {};
          this.ranking_media = {};
          if (response.ranking_valor.length > 0) {
            response.ranking_valor.forEach((value, index) => {
              valor.push(value);
            });

            valor.sort(function (a, b) {
              if (a.valor < b.valor) {
                return +1;
              } else {
                return false;
              }
            });

            this.ranking_valor = valor;
          }

          if (response.ranking_quantidade.length > 0) {
            response.ranking_quantidade.forEach((value, index) => {
              quantidade.push(value);
            });

            quantidade.sort(function (a, b) {
              if (a.quantidade < b.quantidade) {
                return +1;
              } else {
                return false;
              }
            });

            this.ranking_quantidade = quantidade;
          }

          if (response.ranking_media.length > 0) {
            response.ranking_media.forEach((value, index) => {
              media.push(value);
            });

            media.sort(function (a, b) {
              if (a.media < b.media) {
                return +1;
              } else {
                return false;
              }
            });

            this.ranking_media = media;
          }

          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handlePageChange(value) {
      this.fetchModules(true, value);
    },
  },
  created() {
    this.fetchModules();
  },
  watch: {
    perPage() {
      this.fetchModules();
    },
  },
};
</script>
<style lang="scss">
.ranking-gold {
  color: #ffd700;
}
.ranking-silver {
  color: #c0c0c0;
}
.ranking-bronze {
  color: #cd7f32;
}
</style>
 