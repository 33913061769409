 
<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-card
          footer="Selecione uma regional"
          footer-class="text-center text-weight-bold"
        >
          <h6 class="text-primary font-weight-bold mb-2">Regionais</h6>
          <b-overlay
            :show="isLoadingRegionais"
            opacity="0.70"
            variant="transparent"
            rounded="sm"
          >
            <div class="regional-style overflow-auto">
              <draggable
                :list="listRegionais"
                tag="ul"
                group="people"
                class="list-group list-group-flush cursor-move"
              >
                <b-list-group-item
                  v-for="(listItem, index) in listRegionais"
                  :key="index"
                  tag="li"
                  @click="sectionRegional(listItem)"
                >
                  <div class="d-flex">
                    <div class="ml-25">
                      <b-card-text class="mb-0 font-weight-bold">
                        {{ listItem.nome }}
                      </b-card-text>
                    </div>
                  </div>
                </b-list-group-item>
              </draggable>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col md="6" class="mt-sm-2 mt-md-0">
        <b-card class="border-primary">
          <div>
            <draggable
              :list="regionalSelecionada"
              tag="ul"
              group="people"
              class="list-group list-group-flush cursor-move"
            >
              <div v-if="regionalSelecionada.length == 0">
                <h4 class="text-primary text-center font-weight-bold">
                  clique em uma regional ou a arraste aqui
                </h4>
              </div>
              <b-list-group-item
                v-for="(listItem, index) in regionalSelecionada"
                :key="index"
                tag="li"
              >
                <b-row :disabled="index == 0 ? true : false">
                  <b-col lg="10">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ listItem.nome }}
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </draggable>
          </div>
        </b-card>
        <b-card>
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                disabled
                size="sm"
                href="#rankingGeral"
              >
                S1
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                disabled
                size="sm"
                href="#rankingGeral"
              >
                S2
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                disabled
                size="sm"
                href="#rankingGeral"
              >
                S3
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          footer="Selecione uma agencia"
          footer-class="text-center text-weight-bold"
        >
          <h6 class="text-primary font-weight-bold mb-2">Agencias</h6>
          <b-overlay
            :show="isLoadingAgencias"
            opacity="0.70"
            variant="transparent"
            rounded="sm"
          >
            <div
              class="list-group list-group-flush agencia-style overflow-auto"
              v-show="regionalSelecionada.length > 0"
            >
              <b-list-group-item
                v-for="(listItem, index) in moduleDetails.regionais.agencias"
                :key="index"
                tag="li"
                @click="produtoShow(true, listItem)"
              >
                <div class="d-flex">
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ listItem.nome }}
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </div>
          </b-overlay>
          <div v-show="regionalSelecionada == 0" class="text-center">
            <h2>Selecione uma regional para listar as agencias!</h2>
            <p class="mt-2">
              <b-img
                fluid
                width="225"
                :src="require('@/assets/images/pages/vault.svg')"
              />
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <section v-if="showProduto == true">
      <produtos-rank :key="key"/>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { BListGroupItem, BAvatar, BRow, BCol } from "bootstrap-vue";
import draggable from "vuedraggable";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ProdutosRank from "./ProdutosRank.vue";

export default {
  components: {
    BAvatar,
    BListGroupItem,
    BRow,
    BCol,
    draggable,
    mapActions,
    mapState,
    ProdutosRank,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoadingRegionais: true,
      isLoadingAgencias: false,
      listRegionais: [],
      regionalSelecionada: [],
      order: 1,
      showProduto: false,
      key: 1,
    };
  },
  computed: {
    ...mapState("connectAPI", ["modules", "moduleDetails"]),
  },
  methods: {
    produtoShow(value, listItem) {
      this.showProduto = false;
      this.key++;
      localStorage.removeItem("produto_id");
      localStorage.setItem("agencia_id", listItem.id);
      this.showProduto = value;
    },

    fetchModules(paginated = false, page = 1) {
      this.isLoadingRegionais = true;
      this.$store
        .dispatch("connectAPI/fetchModules", {
          pathAPI: "regionais",
          paginated: paginated,
        })
        .then((response) => {
          response.forEach((value, index) => {
            this.listRegionais.push({
              nome: value.nome,
              id: value.id,
            });
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoadingRegionais = false;
        });
    },

    sectionRegional(values) {
      this.showProduto = false;
      this.listRegionais.forEach((value, index) => {
        if (value.nome == values.nome) {
          this.listRegionais.splice(index, 1);
          this.regionalSelecionada.push(value);
          this.regionalSelecionada[0].order = this.order;
        }
      });
    },

    fetchAgencias(value) {
      localStorage.setItem("regional_id", value[0].id);
      this.isLoadingAgencias = true;
      this.$store
        .dispatch("connectAPI/fetchDetails", {
          pathAPI: "regionais",
          paginated: false,
          id: value[0].id,
        })
        .then((response) => {})
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoadingAgencias = false;
        });
    },

    listRegionalSelecionada(values) {
      this.showProduto = false;
      if (values.length > 1) {
        let oldOrder = 0;
        values.forEach((value, index) => {
          if (value.order > oldOrder) {
            this.listRegionais.push(value),
              this.regionalSelecionada.splice(index, 1);
          }
          oldOrder = value.order;
        });
        this.order = 0;
      }
      this.fetchAgencias(this.regionalSelecionada);
    },
  },
  created() {
    this.fetchModules();
  },
  watch: {
    regionalSelecionada(values) {
      this.showProduto = false;
      if (values.length == 0) {
        this.regionalSelecionada[0].order = this.order;
      } else {
        values.forEach((value, index) => {
          this.regionalSelecionada[index].order = this.order++;
        });
      }

      this.listRegionalSelecionada(values);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/tour.scss";
.agencia-style {
  width: 100%;
  height: 200px;
}
.regional-style {
  width: 100%;
  height: 365px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(75, 74, 74, 0.473);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(27, 27, 27);
  border-radius: 10px;
}
.text-format {
  width: 2.3ch;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
</style>