<template>
  <div>
    <h6 class="text-primary font-weight-bold mb-2">Produtos</h6>
    <b-row>
      <b-col class="mb-1" lg="2" v-for="(tr, index) in modules.produtos" :key="index">
				<b-button
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					variant="outline-primary"
					@click="rankingShow(true, tr)"
					block
          size="sm"
					href="#rankingGeral"
				>
					{{tr.nome}}
				</b-button>
      </b-col>
    </b-row>
		<section id="rankingGeral" v-if="showRanking == true">
      <list-ranking :key="key"/>
    </section>
  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ListRanking from "./ListRanking.vue";

export default {
  components: {
    mapActions,
    mapState,
		ListRanking
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Variáveis Global
      isLoading: true,
      key: 1,
      produtoId: null,

			showRanking: false,
    };
  },
  computed: {
    ...mapState("connectAPI", ["modules"]),
  },
  methods: {
		rankingShow(value, tr) {
      localStorage.setItem('produto_id', tr.id);
      this.produtoId = localStorage.setItem('produto_id', tr.id);
      this.key++;
      this.showRanking = value;
    },


    /*
    / Listar e paginar
    */
    fetchModules(paginated = false, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("connectAPI/fetchModules", {
          pathAPI: "produtos",
          paginated: paginated,
          page: page,
          per_page: this.perPage,
        })
        .then((response) => {})
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchModules();
  },
};
</script>
